import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const baseUrl = process.env.REACT_APP_API_URL;

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().loginData?.userToken;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
        return headers;
      }
    },
    responseHandler: async (response) => {
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.indexOf('application/pdf') !== -1) {
        return new Blob([await response.blob()]);
      }
      return response.json();
    },
  }),
  tagTypes: ["Shows", "Facilities", "Contacts", "Contractors", "CarRentals",
            "Expenses", "FlightCarriers", "Hotels", "LeadSupervisors", "ServiceRequests",
            "InstallDismantle", "Itinerary", "TravelExpenses",
            "ClientBranches", "Clients", "ClientConsumables", "ClientJobs", "FreightCarrier"],
  endpoints: (build) => ({
    getJobDetails: build.query({
      query: (jobId) => ({
        url: `/api/Job/show-info?jobId=${jobId}`,
        method: "GET",
      }),
    }),
    updateJobDetails: build.mutation({
      query: (body) => ({
        url: `/api/Job/show-info`,
        method: "PUT",
        body
      }),
      invalidatesTags: ["ClientJobs"],
    }),
    updateJobStatus: build.mutation({
      query: (body) => ({
        url: `/api/Job/job-status`,
        method: "PUT",
        body
      })
    }),
    getPersonalTaskList: build.query({
      query: ({ userId, status = "open", paginationModel, sortModel, searchText = "" }) => ({
        url: `/api/Job/job-personaltasklist`,
        method: "POST",
        body: {
          jobStatus: status,
          searchText: searchText,
          pageNumber: paginationModel.page,
          pageSize: paginationModel.pageSize,
          sortProperty: sortModel[0]?.field,
          sortDirection: sortModel[0]?.sort,
        },
      }),
    }),
    updatePersonalTaskListColor: build.mutation({
      query: (body) => ({
        url: `/api/Job/job-personaltasklist-color`,
        method: "POST",
        body,
      }),
    }),
    getJobChecklist: build.query({
      query: ({ status = "open", paginationModel, sortModel, searchText = "" }) => ({
        url: `/api/Job/job-checklist`,
        method: "POST",
        body: {
          jobStatus: status,
          searchText: searchText,
          pageNumber: paginationModel.page,
          pageSize: paginationModel.pageSize,
          sortProperty: sortModel[0]?.field,
          sortDirection: sortModel[0]?.sort,
        },
      }),
    }),
    updateJobChecklistColor: build.mutation({
      query: (body) => ({
        url: `/api/Job/job-checklist-color`,
        method: "POST",
        body,
      }),
    }),
    /* ******** Show Name Endpoints ************* */
    getShowNames: build.query({
      query: (name) => ({
        url: `api/Lookup/show-names?filter=${name}`,
        // url: `api/Lookup/show-names`,
        method: "GET",
      }),
      providesTags: ["Shows"],
    }),
    createShowName: build.mutation({
      query: (name) => ({
        url: `/api/Lookup/show-name`,
        method: "POST",
        body: name,
      }),
      invalidatesTags: ["Shows", "ClientJobs"],
    }),
    updateShowName: build.mutation({
      query: (name) => ({
        url: `api/Lookup/show-name`,
        method: "PUT",
        body: name,
      }),
      invalidatesTags: ["Shows", "ClientJobs"],
    }),
    deleteShowName: build.mutation({
      query: (id) => ({
        url: `/api/Lookup/show-name?id=${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Shows", "ClientJobs"],
    }),
    /* ******** Facility Endpoints ************* */
    getFacilities: build.query({
      query: (name) => ({
        url: `/api/Lookup/facilities?filter=${name}`,
        method: "GET",
      }),
      providesTags: ["Facilities"],
    }),
    createFacility: build.mutation({
      query: (name) => ({
        url: `/api/Lookup/facility`,
        method: "POST",
        body: name,
      }),
      invalidatesTags: ["Facilities"],
    }),
    updateFacililty: build.mutation({
      query: (name) => ({
        url: `api/Lookup/facility`,
        method: "PUT",
        body: name,
      }),
      invalidatesTags: ["Facilities"],
    }),
    updateFacility: build.mutation({
      query: (name) => ({
        url: `api/Lookup/facility`,
        method: "PUT",
        body: name,
      }),
      invalidatesTags: ["Facilities"],
    }),
    deleteFacility: build.mutation({
      query: (id) => ({
        url: `/api/Lookup/facility?id=${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Facilities"],
    }),
    /* ******** setup dismantle ************* */
    createDismantle: build.mutation({
      query: (body) => ({
        url: `api/Job/setup-dismantle`,
        method: "POST",
        body
      }),
      invalidatesTags: ["Dismantle"],
    }),
    updateDismantle: build.mutation({
      query: (body) => ({
        url: `api/Job/setup-dismantle`,
        method: 'PUT',
        body
      })
    }),
    deleteDismantle: build.mutation({
      query: (id) => ({
        url: `api/Job/setup-dismantle?id=${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Dismantle"],
    }),
    /* ******** Contact Endpoints ************* */
    getContacts: build.query({
      query: (contractorId) => ({
        url: `/api/Lookup/contacts?contractorId=${contractorId}`,
        method: "GET",
      }),
      providesTags: ["Contacts"],
    }),
    createContact: build.mutation({
      query: (name) => ({
        url: `/api/Lookup/contact`,
        method: "POST",
        body: name,
      }),
      invalidatesTags: ["Contacts", "Contractors", "ClientBranches"],
    }),
    updateContact: build.mutation({
      query: (contact) => ({
        url: `/api/Lookup/contact`,
        method: "PUT",
        body: contact,
      }),
      invalidatesTags: ["Contacts", "Contractors", "ClientBranches"],
    }),
    deleteContact: build.mutation({
      query: (id) => ({
        url: `/api/Lookup/contact?id=${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Contacts", "Contractors", "ClientBranches"],
    }),
    /* ******** Contractor Endpoints ************* */
    getContractors: build.query({
      query: (name) => ({
        url: `/api/Lookup/contractors?filter=${name}`,
        method: "GET",
      }),
      providesTags: ["Contractors"],
    }),

    createContractor: build.mutation({
      query: (name) => ({
        url: `/api/Lookup/contractor`,
        method: "POST",
        body: name,
      }),
      invalidatesTags: ["Contractors"],
    }),

    updateContractor: build.mutation({
      query: (contact) => ({
        url: `/api/Lookup/contractor`,
        method: "PUT",
        body: contact,
      }),
      invalidatesTags: ["Contractors"],
    }),

    deleteContractor: build.mutation({
      query: (id) => ({
        url: `/api/Lookup/contractor?id=${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Contractors"],
    }),
    //************************************/
    // ******** State List Endpoint *************
    getStates: build.query({
      query: () => ({
        url: `/api/Lookup/states`,
        method: "GET",
      }),
    }),
    //************************************/
    // ******** Country List Endpoint *************
    getCountries: build.query({
      query: () => ({
        url: `/api/Lookup/countries`,
        method: "GET",
      }),
    }),
    //************************************/
    //************************************/


    /* ******** Exhibits Endpoints ************* */    
    getClientInventoryItems: build.query({
      query: (clientId) => {
        const url = `api/Lookup/client-unassigned-items?clientId=${clientId}`;
        return {
          url,
          method: 'GET',
        };
      },
    }),
    getClientAllInventoryItems: build.query({
      query: (clientId) => {
        const url = `api/Lookup/client-available-items?clientId=${clientId}`;
        return {
          url,
          method: 'GET',
        };
      },
    }),

    getCmcInventoryItems: build.query({
      query: () => {
        const url = `api/Lookup/internal-unassigned-items`;
        return {
          url,
          method: 'GET',
        };
      },
    }),
    getAssignedExhibitItems: build.query({
      query: (exhibitId) => {
        const url = `api/Lookup/assigned-exhibit-items?exhibitId=${exhibitId}`;
        return {
          url,
          method: 'GET',
        };
      },
    }),
    updateItemToExhibit: build.mutation({
      query: (data) => ({
        url: (data.itemType === 'CMC') ? `api/lookup/internal-item` : `api/lookup/client-item`,
        method: (data.itemType === 'CMC') ? "POST" : "PUT",
        body: data,
      })
    }),
    removeItemFromExhibit: build.mutation({
      query: (data) => ({
        url: `api/Lookup/exhibit-item`,
        method: "DELETE",
        body: data,
      })
    }),
    //************************************/
    //************************************/
    /* ******** Exhibits Endpoints ************* */
    clientExhibit: build.query({
      query: (body) => ({
        url: `/api/Lookup/client-exhibits`,
        method: "POST",
        body: body,
      })
    }),
    getExhibits: build.query({
      query: (params) => {
        const url = params.filter ? `api/Lookup/exhibits?clientId=${params.clientId}&filter=${params.filter}`: `api/Lookup/exhibits?clientId=${params.clientId}`;
        return {
          url,
          method: 'GET',
        };
      },
    }),
    getExhibit: build.query({
      query: (exhibitId) => {
        const url = `api/Lookup/exhibit?exhibitId=${exhibitId}`;
        return {
          url,
          method: 'GET',
        };
      },
    }),  
    createExhibit: build.mutation({
      query: (name) => ({
        url: `/api/Lookup/exhibit`,
        method: "POST",
        body: name,
      })
    }),
    updateExhibit: build.mutation({
      query: (name) => ({
        url: `api/Lookup/exhibit`,
        method: "PUT",
        body: name,
      })
    }),
    deleteExhibit: build.mutation({
      query: (id) => ({
        url: `/api/Lookup/exhibit?id=${id}`,
        method: "DELETE",
      })
    }),
    //************************************/
     /* ******** Consumable Items Endpoints ************* */    
     getConsumableItems: build.query({
      query: (clientId) => {
        const url = clientId ? `api/lookup/client-unassigned-consumables?clientId=${clientId}` : 'api/lookup/client-unassigned-consumables';
        return {
          url,
          method: 'GET',
        };
      },
    }),
    getAssignedConsumables: build.query({
      query: (exhibitGiveAwayId) => {
        const url = `/api/Lookup/client-assigned-consumables?exhibitGiveAwayId=${exhibitGiveAwayId}`;
        return {
          url,
          method: 'GET',
        };
      },
    }),
    createExhibitConsumable: build.mutation({
      query: (body) => ({
        url: `/api/Lookup/consumable`,
        method: "POST",
        body: body,
      })
    }),
    removeExhibitConsumable: build.mutation({
      query: (body) => ({
        url: `api/Lookup/consumable`,
        method: "DELETE",
        body: body
      })
    }),
    //************************************/
    /* ******** Exhibit GiveAway Endpoints ************* */    
     getExhibitGiveAway: build.query({
      query: (jobId) => {
        const url = `/api/Job/exhibit-giveaway?jobId=${jobId}`;
        return {
          url,
          method: 'GET',
        };
      },
    }),
    updateExhibitGiveAway: build.mutation({
      query: (body) => ({
        url: `/api/Job/exhibit-giveaway`,
        method: "PUT",
        body: body,
      })
    }),
    //************************************/

    /* ******** Install Dismantle Endpoints ************* */
    getInstallDismantle: build.query({
      query: (jobId) => ({
        url: `/api/Job/install-dismantle?jobId=${jobId}`,
        method: "GET",
      }),
      providesTags: ["InstallDismantle"],
    }),
    updateInstallDismantle: build.mutation({
      query: (body) => ({
        url: `/api/Job/install-dismantle`,
        method: "PUT",
        body
      }),
      invalidatesTags: ["InstallDismantle"],
    }),

     /* ******** Return Inventory Endpoints ************* */    
    updateReturnInventory: build.mutation({
      query: (body) => {
        const url = `/api/Lookup/return-inventory`;
        return {
          url,
          method: 'PUT',
          body: body,
        };
      },
    }),

    //************************************/
    /* ******** Shipments Endpoints ************* */
    getJobShipments: build.query({
      query: (jobId) => {
        const url = `api/job/shipments?jobId=${jobId}`;
        return {
          url,
          method: 'GET',
        };
      },
    }),
    getJobShipment: build.query({
      query: (shipmentId) => {
        const url = `api/job/shipment?shipmentId=${shipmentId}`;
        return {
          url,
          method: 'GET',
        };
      },
    }),
    createJobShipment: build.mutation({
      query: (body) => {
        const url = `api/job/shipment`;
        return {
          url,
          method: 'POST',
          body: body,
        };
       },
        invalidatesTags: ["Expenses"],
    }),

    getClientFacilityAddress: build.query({
      query: (jobId) => {
        const url = `/api/Job/shipment-autopopulate-data?jobId=${jobId}`;
        return {
          url,
          method: 'GET',
        };
      },
    }),
    printJobShipment: build.mutation({
      query: (body) => {
        const url = `api/job/shipment-print`;
        return {
          url,
          method: 'POST',
          body: body,
        };
      },
    }),
    printJobShowInfo: build.mutation({
      query: (body) => {
        const url = `api/job/showinfo-print`;
        return {
          url,
          method: 'POST',
          body: body,
        };
      },
    }),
      removeShipment: build.mutation({
        query: (id) => ({
              url: `/api/job/shipment?id=${id}`,
            method: "DELETE",
        }),
        invalidatesTags: ["Expenses"],
    }),
    //************************************/

    /* ******** Shipment Items Endpoints ************* */
    getItemsToShip: build.query({
        query: (params) => {
            const url = `/api/Lookup/items-to-ship?jobId=${params.jobId}&shipmentDirection=${params.shipmentDirection}`;
        return {
          url,
          method: 'GET',
        };
      },
    }),
    getItemsInShipment: build.query({
      query: (shipmentId) => {
        const url = `/api/Lookup/items-in-shipment?shipmentId=${shipmentId}`;
        return {
          url,
          method: 'GET',
        };
      },
    }),
    createShipmentItem: build.mutation({
      query: (body) => {
        const url = `/api/Lookup/shipment-item`;
        return {
          url,
          method: 'POST',
          body: body,
        };
      },
    }),
    updateShipmentItem: build.mutation({
      query: (body) => {
        const url = `/api/Lookup/shipment-item`;
        return {
          url,
          method: 'PUT',
          body: body,
        };
      },
    }),
    removeShipmentItem: build.mutation({
      query: (body) => {
        const url = `/api/Lookup/shipment-item`;
        return {
          url,
          method: 'DELETE',
          body: body,
        };
      },
    }),
    //************************************/

    //************************************/
    /* ******** Freight Carriers Endpoints ************* */
    getFreightCarriers: build.query({
      query: (string) => {
        const url = string ? `/api/Lookup/freight-carrier?filter=${string}` : `/api/Lookup/freight-carrier`;
        return {
          url,
          method: 'GET',
        };
      },
    }),
    createFreightCarrier: build.mutation({
      query: (body) => ({
        url: `/api/Lookup/freight-carrier`,
        method: "POST",
        body: body
      }),
      invalidatesTags: ["FreightCarrier"],
    }),
    getFreightCarrierDetail: build.query({
      query: (string) => {
        const url = `/api/Lookup/freight-carrier?filter=${string}`;
        return {
          url,
          method: 'GET',
        };
      },
      providesTags: ["FreightCarrier"],
    }),
    updateFreightCarrier: build.mutation({
      query: (body) => ({
        url: `/api/Lookup/freight-carrier`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["FreightCarrier"],
    }),
    deleteFreightCarrier: build.mutation({
      query: (id) => ({
        url: `/api/Lookup/freight-carrier?id=${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["FreightCarrier"],
    }),
    //************************************/

    // ******** Services Request Endpoint *************
    getServicesRequest: build.query({
      query: (jobId) => ({
          url: `/api/Job/service-request?jobId=${jobId}`,
          method: "GET",
      }),
      providesTags: ["ServiceRequests"],
    }),
    updateServicesRequest: build.mutation({
      query: (body) => ({
        url: `/api/Job/service-request`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["ServiceRequests", "Expenses"],
    }),

    //************************************/   

    /* ******** Internal Users Endpoints ************* */
    getInternalUsers: build.query({
      query: (type) => ({
      url: `/api/Account/users-by-type?userType=${type}`,
      method: "GET",
      }),
    }),
    //************************************/

    /* ******** Car Rental Endpoints ************* */
    getCarRentals: build.query({
      query: (name) => ({
        url: `/api/Lookup/car-rentals?filter=${name}`,
        method: "GET",
      }),
      providesTags: ["CarRentals"],
    }),
    createCarRental: build.mutation({
      query: (body) => ({
        url: `/api/Lookup/car-rental`,
        method: "POST",
        body
      }),
      invalidatesTags: ["CarRentals"],
    }),
    updateCarRental: build.mutation({
      query: (body) => ({
        url: `/api/Lookup/car-rental`,
        method: "PUT",
        body
      }),
      invalidatesTags: ["CarRentals"],
    }),
    deleteCarRental: build.mutation({
      query: (id) => ({
        url: `/api/Lookup/car-rental?id=${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["CarRentals"],
    }),
    //************************************/

    /* ******** Flight Carrier Endpoints ************* */
    getCarriers: build.query({
      query: (name) => ({
        url: `/api/Lookup/carriers?filter=${name}`,
        method: "GET",
      }),
      providesTags: ["FlightCarriers"],
    }),
    createCarrier: build.mutation({
      query: (body) => ({
        url: `/api/Lookup/carrier`,
        method: "POST",
        body
      }),
      invalidatesTags: ["FlightCarriers"],
    }),
    updateCarrier: build.mutation({
      query: (body) => ({
        url: `/api/Lookup/carrier`,
        method: "PUT",
        body
      }),
      invalidatesTags: ["FlightCarriers"],
    }),
    deleteCarrier: build.mutation({
      query: (id) => ({
        url: `/api/Lookup/carrier?id=${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["FlightCarriers"],
    }),
    //************************************/

    /* ******** Hotel Endpoints ************* */
    getHotels: build.query({
      query: (name) => ({
        url: `/api/Lookup/hotels?filter=${name}`,
        method: "GET",
      }),
      providesTags: ["Hotels"],
    }),
    createHotel: build.mutation({
      query: (body) => ({
        url: `/api/Lookup/hotel`,
        method: "POST",
        body
      }),
      invalidatesTags: ["Hotels"],
    }),
    updateHotel: build.mutation({
      query: (body) => ({
        url: `/api/Lookup/hotel`,
        method: "PUT",
        body
      }),
      invalidatesTags: ["Hotels"],
    }),
    deleteHotel: build.mutation({
      query: (id) => ({
        url: `/api/Lookup/hotel?id=${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Hotels"],
    }),

    /* ******** Flight Endpoints ************* */
    deleteFlight: build.mutation({
      query: (id) => ({
        url: `/api/Lookup/flight?id=${id}`,
        method: "DELETE",
      }),
    }),


    /* ******** Lead Supervisor Endpoints ************* */
    getLeadSupervisors: build.query({
      query: (name) => ({
        url: `/api/Account/lead-supervisors?filter=${name}`,
        method: "GET",
      }),
      providesTags: ["LeadSupervisors"],
    }),
    createLeadSupervisor: build.mutation({
      query: (body) => ({
        url: `/api/Account/register`,
        method: "POST",
        body
      }),
      invalidatesTags: ["LeadSupervisors"],
    }),
    updateLeadSupervisor: build.mutation({
      query: (body) => ({
        url: `/api/Account/lead-supervisor`,
        method: "PUT",
        body
      }),
      invalidatesTags: ["LeadSupervisors"],
    }),
    deleteLeadSupervisor: build.mutation({
      query: (id) => ({
        url: `/api/Account/lead-supervisor?id=${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["LeadSupervisors"],
    }),
    //************************************/
    /* ******** Expenses Endpoints ************* */
    getExpenses: build.query({
      query: (jobId) => ({
        url: `/api/Job/expenses?jobId=${jobId}`,
        method: "GET",
      }),
      providesTags: ["Expenses"],
    }),
    updateExpense: build.mutation({
      query: (body) => ({
        url: `/api/Job/expenses`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["ServiceRequests", "Expenses"],
    }),
    deleteExpense: build.mutation({
      query: (id) => ({
        url: `/api/Job/expense?id=${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ServiceRequests", "Expenses"],
    }),
    //************************************/

    /* ******** Itinerary Endpoints ************* */
    getItinerary: build.query({
      query: ({jobId, userId, type}) => ({
        url: `/api/Job/itinerary?jobId=${jobId}&userId=${userId}&type=${type}`,
        method: "GET",
      }),
      providesTags: ["Itinerary"],
    }),
    updateItinerary: build.mutation({
      query: (body) => ({
        url: `/api/Job/itinerary`,
        method: "PUT",
        body
      }),
      invalidatesTags: ["Itinerary", "TravelExpenses", "InstallDismantle"],
    }),
    //************************************/

    /* ******** Install Dismantle - Setup Dismantle Endpoints ************* */
    createIDSetupDismantle: build.mutation({
      query: (body) => ({
        url: `api/Lookup/installdismantle-setup-dismantle`,
        method: "POST",
        body
      }),
        invalidatesTags: ["Dismantle","Expenses"],
    }),
    updateIDSetupDismantle: build.mutation({
      query: (body) => ({
        url: `api/Lookup/installdismantle-setup-dismantle`,
        method: 'PUT',
        body
    }),
    invalidatesTags: ["Expenses"],
    }),
    deleteIDSetupDismantle: build.mutation({
      query: (id) => ({
        url: `api/Lookup/installdismantle-setup-dismantle?id=${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Dismantle","Expenses"],
    }),
    //************************************/

    /* ******** Travel Expenses Endpoints ************* */
    getTravelExpenses: build.query({
      query: (id) => ({
        url: `/api/Lookup/travel-expenses?installDismantleId=${id}`,
        method: "GET",
      }),
      providesTags: ["TravelExpenses"],
    }),
    createTravelExpense: build.mutation({
      query: (body) => ({
        url: `/api/Lookup/travel-expense`,
        method: "POST",
        body: body,
      }),
        invalidatesTags: ["TravelExpenses", "Expenses"],
    }),
    updateTravelExpense: build.mutation({
      query: (body) => ({
        url: `/api/Lookup/travel-expense`,
        method: "PUT",
        body: body,
      }),
        invalidatesTags: ["TravelExpenses","Expenses"],
    }),
    deleteTravelExpense: build.mutation({
      query: (id) => ({
        url: `/api/Lookup/travel-expense?id=${id}`,
        method: "DELETE",
      }),
        invalidatesTags: ["TravelExpenses","Expenses"],
    }),
    //************************************/

     /* ******** CLient Branch Endpoints ************* */
     getBranch: build.query({
      query: ({clientId, filter}) => ({
        url: `/api/Lookup/client-branches?clientId=${clientId}&filter=${filter}`,
        method: "GET",
      }),
      providesTags: ["ClientBranches"],
    }),

    createBranch: build.mutation({
      query: (name) => ({
        url: `/api/Lookup/client-branch`,
        method: "POST",
        body: name,
      }),
      invalidatesTags: ["ClientBranches"],
    }),

    updateBranch: build.mutation({
      query: (contact) => ({
        url: `/api/Lookup/client-branch`,
        method: "PUT",
        body: contact,
      }),
      invalidatesTags: ["ClientBranches"],
    }),

    deleteBranch: build.mutation({
      query: (id) => ({
        url: `/api/Lookup/client-branch?id=${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ClientBranches"],
    }),
    //************************************/
    /* ******** Client Name Endpoints ************* */
    getClientNames: build.query({
      query: (name) => ({
        url: `api/Lookup/clients?filter=${name}`,
        method: "GET",
      }),
      providesTags: ["Clients"],
    }),
    createClientName: build.mutation({
      query: (name) => ({
        url: `/api/Lookup/client`,
        method: "POST",
        body: name,
      }),
      invalidatesTags: ["Clients"],
    }),
    updateClientName: build.mutation({
      query: (name) => ({
        url: `api/Lookup/client`,
        method: "PUT",
        body: name,
      }),
      invalidatesTags: ["Clients"],
    }),
    deleteClientName: build.mutation({
      query: (id) => ({
        url: `/api/Lookup/client?id=${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Clients"],
    }),
    //************************************/
    /* ******** Client Consumables Endpoints ************* */    
    getClientConsumables: build.query({
      query: (params) => {
        const url = params.sortDirection && params.sortProperty ?  `api/Lookup/client-consumables?clientId=${params.clientId}&sortProperty=${params.sortProperty}&sortDirection=${params.sortDirection}`: `api/Lookup/client-consumables?clientId=${params.clientId}`;
        return {
          url,
          method: 'GET',
        };
      }
    }),
    createClientConsumable: build.mutation({
      query: (consumable) => ({
        url: `/api/Lookup/client-consumable`,
        method: "POST",
        body: consumable,
      })
    }),

    updateClientConsumable: build.mutation({
      query: (consumable) => ({
        url: `/api/Lookup/client-consumable`,
        method: "PUT",
        body: consumable,
      })
    }),

    deleteClientConsumable: build.mutation({
      query: (id) => ({
        url: `/api/Lookup/client-consumable?id=${id}`,
        method: "DELETE",
      })
    }),

    printClientConsumable: build.mutation({
      query: (body) => ({
        url: `/api/Lookup/consumable-print`,
        method: "POST",
        body: body,
      })
    }),
    //************************************/

    /* ******** Client Inventories Endpoints ************* */    
    getClientInventories: build.query({
      query: (body) => {
        const url = `/api/Client/inventory-items`;
        return {
          url,
          method: 'POST',
          body: body,
        };
      },
      providesTags: ["ClientInventories"],
    }),
    createClientInventory: build.mutation({
      query: (inventory) => ({
        url: `/api/Client/inventory-item`,
        method: "POST",
        body: inventory,
      }),
      invalidatesTags: ["ClientInventories"],
    }),

    updateClientInventory: build.mutation({
      query: (inventory) => ({
        url: `/api/Client/inventory-item`,
        method: "PUT",
        body: inventory,
      }),
      invalidatesTags: ["ClientInventories"],
    }),

    deleteClientInventory: build.mutation({
      query: (id) => ({
        url: `/api/Client/inventory-item?id=${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ClientInventories"],
    }),
    //************************************/
 /* ******** Client Job Endpoints ************* */    
    getClientJobs: build.query({
      query: (params) => {
        // const url = `/api/Client/client-job?clientId=${clientId}`;
        const url = params.sortDirection && params.sortProperty ?  `api/Client/client-job?clientId=${params.clientId}&sortProperty=${params.sortProperty}&sortDirection=${params.sortDirection}`: `api/Client/client-job?clientId=${params.clientId}`;
        return {
          url,
          method: 'GET',
        };
      },
      providesTags: ["ClientJobs"],
    }),
    createClientJob: build.mutation({
      query: (job) => ({
        url: `/api/Client/client-job`,
        method: "POST",
        body: job,
        responseHandler: "text",
      }),      
      invalidatesTags: ["ClientJobs"],
    }),
    //************************************/

        /* ******** Pro number Endpoints ************* */    
        getProNumbers: build.query({
          query: (shipmentId) => {
            const url = `/api/Lookup/shipment-pronumber?shipmentId=${shipmentId}`;
            return {
              url,
              method: 'GET',
            };
          },
        }),
        createProNumber: build.mutation({
          query: (body) => ({
            url: `/api/Lookup/shipment-pronumber`,
            method: "POST",
            body: body,
          }),
        }),
    
        updateProNumber: build.mutation({
          query: (body) => ({
            url: `/api/Lookup/shipment-pronumber`,
            method: "PUT",
            body: body,
          }),
        }),
    
        deleteProNumber: build.mutation({
          query: (id) => ({
            url: `/api/Lookup/shipment-pronumber?id=${id}`,
            method: "DELETE",
          }),
        }),
        //************************************/
  }),
});

// export react hook
export const {
  useGetJobDetailsQuery,
  useUpdateJobDetailsMutation,
  useUpdateJobStatusMutation,
  useGetPersonalTaskListQuery,
  useUpdatePersonalTaskListColorMutation,
  useGetJobChecklistQuery,
  useUpdateJobChecklistColorMutation,
  useGetShowNamesQuery,
  useCreateShowNameMutation,
  useUpdateShowNameMutation,
  useDeleteShowNameMutation,
  useGetFacilitiesQuery,
  useCreateFacilityMutation,
  useUpdateFacililtyMutation,
  useUpdateFacilityMutation,
  useDeleteFacilityMutation,
  useDeleteDismantleMutation,
  useCreateDismantleMutation,
  useUpdateDismantleMutation,
  useGetContactsQuery,
  useCreateContactMutation,
  useDeleteContactMutation,
  useUpdateContactMutation,
  useGetContractorsQuery,
  useCreateContractorMutation,
  useDeleteContractorMutation,
  useUpdateContractorMutation,
  useGetStatesQuery,
  useGetCountriesQuery,
  useGetClientInventoryItemsQuery,
  useGetClientAllInventoryItemsQuery,
  useGetCmcInventoryItemsQuery,
  useGetAssignedExhibitItemsQuery,
  useUpdateItemToExhibitMutation,
  useRemoveItemFromExhibitMutation,
  useClientExhibitQuery,
  useGetExhibitsQuery,
  useGetExhibitQuery,
  useCreateExhibitMutation,
  useUpdateExhibitMutation,
  useDeleteExhibitMutation,
  useGetConsumableItemsQuery,
  useGetAssignedConsumablesQuery,
  useCreateExhibitConsumableMutation,
  useRemoveExhibitConsumableMutation,
  useGetExhibitGiveAwayQuery,
  useUpdateExhibitGiveAwayMutation,
  useGetInstallDismantleQuery,
  useUpdateInstallDismantleMutation,
  useGetJobShipmentsQuery,
  useGetJobShipmentQuery,
  useCreateJobShipmentMutation,
  useGetClientFacilityAddressQuery,
  usePrintJobShipmentMutation,
  usePrintJobShowInfoMutation,
  useGetItemsInShipmentQuery,
  useGetItemsToShipQuery,
  useRemoveShipmentMutation,
  useCreateShipmentItemMutation,
  useUpdateShipmentItemMutation,
  useRemoveShipmentItemMutation,
  useGetFreightCarriersQuery,
  useCreateFreightCarrierMutation,
  useGetFreightCarrierDetailQuery,
  useUpdateFreightCarrierMutation,
  useDeleteFreightCarrierMutation,
  useUpdateReturnInventoryMutation,
  useGetInternalUsersQuery,
  useGetServicesRequestQuery,
  useUpdateServicesRequestMutation,
  useGetCarRentalsQuery,
  useCreateCarRentalMutation,
  useUpdateCarRentalMutation,
  useDeleteCarRentalMutation,
  useGetCarriersQuery,
  useCreateCarrierMutation,
  useUpdateCarrierMutation,
  useDeleteCarrierMutation,
  useGetHotelsQuery,
  useCreateHotelMutation,
  useUpdateHotelMutation,
  useDeleteHotelMutation,
  useDeleteFlightMutation,
  useGetLeadSupervisorsQuery,
  useCreateLeadSupervisorMutation,
  useUpdateLeadSupervisorMutation,
  useDeleteLeadSupervisorMutation,
  useGetExpensesQuery,
  useUpdateExpenseMutation,
  useDeleteExpenseMutation,
  useGetItineraryQuery,
  useUpdateItineraryMutation,
  useGetTravelExpensesQuery,
  useCreateTravelExpenseMutation,
  useUpdateTravelExpenseMutation,
  useDeleteTravelExpenseMutation,
  useCreateIDSetupDismantleMutation,
  useUpdateIDSetupDismantleMutation,
  useDeleteIDSetupDismantleMutation,
  useGetBranchQuery,
  useCreateBranchMutation,
  useDeleteBranchMutation,
  useUpdateBranchMutation,
  useGetClientNamesQuery,
  useCreateClientNameMutation,
  useUpdateClientNameMutation,
  useDeleteClientNameMutation,
  useGetClientConsumablesQuery,
  useCreateClientConsumableMutation,
  useUpdateClientConsumableMutation,
  useDeleteClientConsumableMutation,
  usePrintClientConsumableMutation,
  useGetClientInventoriesQuery,
  useCreateClientInventoryMutation,
  useUpdateClientInventoryMutation,
  useDeleteClientInventoryMutation,
  useGetClientJobsQuery,
  useCreateClientJobMutation,
  useGetProNumbersQuery,
  useCreateProNumberMutation,
  useUpdateProNumberMutation,
  useDeleteProNumberMutation
} = authApi;
// export default authApi.reducer;
