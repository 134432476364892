import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  FormHelperText
} from '@mui/material';
import ActionBar from '../../ui/ActionBar';
import { setAllocatedExhibitItems } from '../../../redux/reducers/exhibit/exhibitSlice';
import ItemList from './ItemList';
import { useGetAssignedExhibitItemsQuery, useGetClientAllInventoryItemsQuery, useUpdateItemToExhibitMutation } from '../../../services/authService';
import { CustomSelect } from '../../../style/mui/customStyle';
import { enqueueSnackbar } from "notistack";
import messages from "../../../utils/messages";

const validationSchema = Yup.object().shape({
  itemId: Yup.number().required('Item is required'), 
  exhibitId: Yup.number().required('Exhibit ID is required'),
  itemType: Yup.string().required('Item Type is required'),
});

function ItemForm(props) {
  const { onClose, onSubmit, showAction, exhibit, clientId} = props;
  
  const [ clientInventoryItemsState,  setClientInventoryItemsState ] = useState(null);
  const [ assignedItems,  setAssignedItems ] = useState(null);
  
  const { data: clientInventoryItems, isFetching: fetchingClientInventoryItems, refetch: refetchClientInventoryItems } = useGetClientAllInventoryItemsQuery(clientId && clientId);
  const { data: assignedExhibitItems, isFetching, refetch: refetchGetAssignedExhibitItems } = useGetAssignedExhibitItemsQuery(exhibit?.id);

  const [updateItemToExhibit, { isLoading, isError }] = useUpdateItemToExhibitMutation();

  useEffect(() => {
    if (clientInventoryItems?.items){
      setClientInventoryItemsState(clientInventoryItems?.items);
    }
  }, [clientInventoryItems]);
  
  useEffect(() => {
    if (assignedExhibitItems?.items){
      setAssignedItems(assignedExhibitItems?.items);
    }
  }, [assignedExhibitItems]);

  const handleItemUpdated = async() => {
    const refetch = await refetchGetAssignedExhibitItems();
    const refetchClientResponse = await refetchClientInventoryItems();
    setClientInventoryItemsState(refetchClientResponse.data.items);
  }

  const formik = useFormik({
    initialValues: {
      itemId: '',
      exhibitId: exhibit?.id,
      itemType: 'Client',
    },
    validationSchema,
    onSubmit: async (values) => {
      const response = await updateItemToExhibit(values);
      if (response.data === true) {
        handleItemUpdated();
        enqueueSnackbar(`${messages.success.updateItemToExhibit}`, { variant: 'success' });
      } else {
        enqueueSnackbar(`${messages.error.prefix} ${response.data ? response.data[0]?.errorMessage : messages.error.generic }`, { variant: 'error' });
      }
    }
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <FormControl fullWidth className='mt-2'>
        <InputLabel htmlFor="itemId">Item</InputLabel>
        <CustomSelect 
          label="Item" 
          name="itemId"
          value={formik.values.itemId}
          onChange={formik.handleChange}
          error={formik.touched.itemId && Boolean(formik.errors.itemId)}
        >
          <MenuItem disabled value="">
            <em>Select Item</em>
          </MenuItem>
          {!fetchingClientInventoryItems && clientInventoryItemsState &&  clientInventoryItemsState.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.displayText}
            </MenuItem>
          ))}
        </CustomSelect>
        {formik.touched.itemId && formik.errors.itemId && (
          <FormHelperText error>
            {formik.errors.itemId}
          </FormHelperText>
        )}
      </FormControl>

      <div className='mt-2'>
        <ActionBar hideDialog={onClose} loadingAdd={isLoading} onAdd={formik.handleSubmit} />
      </div>

      <div className='mt-3 mb-3'>
        <ItemList showAddItem={false} showAction={showAction} items={assignedItems} exhibit={exhibit} setClientInventoryItemsState={setClientInventoryItemsState} handleItemUpdated={handleItemUpdated} />
      </div>
    </form>
  );
}

export default ItemForm;
